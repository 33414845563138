<template>
    <div class="card">
        <Dropdown showClear v-model="companyiaseleccionada" :options="companyias" filter optionLabel="nombre"
            placeholder="Filtro Compañia">
            <template #option="slotProps">
                <div class="row" style="width: 100%;">
                        <div class="col-md-7" @click="seleccion(slotProps.data)"> {{ slotProps.option.nombre }}&nbsp;</div>
                        <div class="col-md-3"><span class="badge badge-primary">{{ slotProps.option.prefijo }}</span>&nbsp;</div>
                        <div class="col-md-1"> <span class="badge badge-secondary">{{ slotProps.option.encurso }}</span>&nbsp;</div>
                        <div class="col-md-1"> <span class="badge badge-success">{{slotProps.option.facturar }}</span></div>
                </div>
            </template>
        </Dropdown>
        <DataTable class="mitabla" stripedRows :value="listadocompanyias" scrollable scrollHeight="800px">
            <Column field="nombre">
                <template #body="slotProps">
                    <div class="row" style="width: 100%;">
                        <div class="col-md-7" style="cursor:pointer;" @click="seleccion(slotProps.data)" >{{ slotProps.data.nombre }}&nbsp;</div>
                        <div class="col-md-3"><span class="badge badge-primary">{{slotProps.data.prefijo }}</span>&nbsp;</div>
                        <div class="col-md-1"><span class="badge badge-secondary">{{slotProps.data.encurso }}</span>&nbsp;</div>
                        <div class="col-md-1"> <span class="badge badge-success">{{slotProps.data.facturar }}</span></div>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import Dropdown from 'primevue/dropdown';
export default {
    props: ['companyias'],
    components: {
        Dropdown,
    },
    data() {
        return {
            listadocompanyias: [],
            companyiaseleccionada: '',
        };
    },
    methods: {
       listadoCompanyas(){ 
            this.listadocompanyias = this.$props.companyias;
            this.auxiliar = this.$props.companyias;
        },
        seleccion(com){
            this.$parent.cargarseleccionada(com);
       }    
    },
    mounted() {
        this.listadoCompanyas();
    },
    watch: {
        companyiaseleccionada(){
            if (this.companyiaseleccionada) {
                this.listadocompanyias = [];
                this.listadocompanyias.push(this.companyiaseleccionada);
            }
            else {
                this.listadocompanyias = this.$props.companyias;
            }
        },
        companyias() {
            this.listadoCompanyas();
        }
    }
};
</script>
<style>
.codigo {
    cursor: pointer !important;
}
.mitabla .p-datatable-thead {
    display: none !important;
}
</style>
